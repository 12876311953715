@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@layer base {
    [type='text'],
    [type='email'],
    [type='url'],
    [type='password'],
    [type='number'],
    [type='date'],
    [type='datetime-local'],
    [type='month'],
    [type='search'],
    [type='tel'],
    [type='time'],
    [type='week'],
    [multiple],
    textarea,
    select {
        @apply block w-full rounded-full border-neutral-200 bg-white text-neutral-900 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:border-neutral-700 dark:bg-neutral-900 dark:text-neutral-200;
    }
}
